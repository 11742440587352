<template>
  <div class="commmon-page">
    <!-- header -->
    <header class="app-header">
      <Header></Header>
    </header>

    <div class="content">
      <div class="content-inner">
        <div class="con-left">
          <img src="@/assets/images/403.png" alt />
        </div>
        <div class="con-right">
          <h2 class="title">403</h2>
          <div class="text">{{$t('tipPage.noAuthLookPage')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/common/Header.vue';

  export default {
    data() {
      return {

      }
    },
    components: {
      Header
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .commmon-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
      position: relative;
      flex: 1 0 auto;
    }

    .app-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .content-inner {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 240px;
      }

      .con-right {
        margin-left: 24px;
        flex: 1 0 auto;
      }

      .title {
        font-size: 70px;
        line-height: 1;
        margin-bottom: 24px;
      }

      .text {
        font-size: 20px;
        line-height: 1;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
</style>
